import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: {
          date: "5.07.2025",
          frase_bienvenida_1: "Todos somos mortales, ",
          frase_bienvenida_2: "hasta el primer beso y la segunda copa de vino    ",
          faltan: "Faltan",
          end_countdown: "Ya ha llegado el momento",
          dias: "días",
          horas: "hs",
          minutos: "min",
          segundos: "seg",
          confirmar: "Confirmar asistencia",
          como: "¿Cómo llegar?",
          ceremonia: {
           titulo: "Ceremonia",
           dia_completo: "Sábado 5 de Julio - 18:30h",
           dia: "Día",
           lugar_t: "Lugar",
           lugar: "Iglesia Parroquial del Sagrario",
           direccion_t: "Dirección",
           direccion: "C. Oficios, s/n, Centro, 18001 Granada"
          },
          celebracion: {
            titulo: "Celebración",    
            dia_completo: "Sábado 5 de Julio  - 20:30h",
            lugar: "Jardines de Siddharta",
            direccion: "Cam. Zute, 41, 18198 Huétor Vega, Granada",           
          },
          galeria: {
            titulo: "Retratos de Nuestro Amor",
            frase: "Un minuto, un segundo, un instante que queda en la eternidad"
          },
          web: {
            titulo: "Tips y Notas",
            frase_1: "Información adicional ",
            frase_2: " para tener en cuenta en la web"
          },
          regalo: {
            titulo: "Regalos",
            frase: "Si deseáis regalarnos algo más que vuestra hermosa presencia..."
          }
        }
      },
      us: {
        translation: {
          date: "7.05.2025",
          frase_bienvenida_1: "We are all mortal, ",
          frase_bienvenida_2: "until the first kiss and the second glass of wine    ",
          faltan: "To Go!",
          end_countdown: "The time has come",
          dias: "days",
          horas: "hs",
          minutos: "min",
          segundos: "sec",
          confirmar: "Confirm with Alberto",
          como: "How to get there?",
          ceremonia: {
           titulo: "Ceremony",
           dia_completo: "Saturday July 5th, 2025 - 18:30PM",
           dia: "Day",
           lugar_t: "Place",
           lugar: "Iglesia Parroquial del Sagrario (Church)",
           direccion_t: "Address",
           direccion: "C. Oficios, s/n, Centro, 18001 Granada"
          },
          celebracion: {
            titulo: "Celebration/Party",    
            dia_completo: "Saturday July 5th, 2025 - 20:30PM",
            lugar: "Jardines de Siddharta (Siddhartha Gardens)",
            direccion: "Cam. Zute, 41, 18198 Huétor Vega, Granada",           
          },
          galeria: {
            titulo: "Portraits of Our Love",
            frase: "A minute, a second, a moment that remains in eternity"
          },
          web: {
            titulo: "Tips and Notes",
            frase_1: "Additional information ",
            frase_2: " to take into account on the website"
          },
          regalo: {
            titulo: "Gifts",
            frase: "If you want to give us something more than your beautiful presence..."
          }
        }
      },
      it: {
        translation: {
          date: "5.07.2025",
          frase_bienvenida_1: "Siamo tutti mortali, ",
          frase_bienvenida_2: "fino al primo bacio e al secondo bicchiere di vino",
          faltan: "Mancano",
          end_countdown: "È giunto il momento",
          dias: "Giorni",
          horas: "ore",
          minutos: "min",
          segundos: "sec",
          confirmar: "Conferma con Alberto",
          como: "Come arrivarci?",
          ceremonia: {
           titulo: "Cerimonia",
           dia_completo: "Sabato 5 di Luglio - 18:30PM",
           dia: "Giorno",
           lugar_t: "Posto",
           lugar: "Iglesia Parroquial del Sagrario (Chiesa)",
           direccion_t: "Indirizzo",
           direccion: "C. Oficios, s/n, Centro, 18001 Granada"
          },
          celebracion: {
            titulo: "Celebrazione",    
            dia_completo: "Sabato 5 di Luglio  - 20:30PM",
            lugar: "Jardines de Siddharta (Giardini Siddharta)",
            direccion: "Cam. Zute, 41, 18198 Huétor Vega, Granada",           
          },
          galeria: {
            titulo: "Ritratti del nostro amore",
            frase: "Un minuto, un secondo, un attimo che resta nell'eternità"
          },
          web: {
            titulo: "Suggerimenti e note",
            frase_1: "Ulteriori informazioni",
            frase_2: " da tenere in considerazione sul web"
          },
          regalo: {
            titulo: "Regali",
            frase: "Se vuoi regalarci qualcosa in più della tua bella presenza..."
          }
        }
      }
    }
  });

export default i18n;