import lottie from "lottie-web";
import anillos from './anillos.json';
import fiesta from './fiesta.json';
import { useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export function Ceremonia() {
  const { t } = useTranslation();
    let animationContainerRing = createRef();
    let animationContainerParty = createRef();
    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerRing.current,
        renderer: "svg",
        loop: true,
        animationData: anillos
        });
        return () => anim.destroy();
    }, [animationContainerRing]);

    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerParty.current,
        renderer: "svg",
        loop: true,
        animationData: fiesta
        });
        return () => anim.destroy();
    }, [animationContainerParty]);

    return (
        <section className="ceremonia-fiesta">
            <div className="sombra-horizontal sombra-top"></div>
            <div className="container">
    <div className="row">
      <div className="col-md-6 col-ceremonia d-flex justify-content-center text-center">
        <div className="box-bordes-adorno d-flex justify-content-center">
          <div className="box-color-col degradez-opcional-var">
            <div className="anim-anillos" ref={animationContainerRing}>
            </div>
            <h3>
              {t("ceremonia.titulo")}</h3>


            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362 23" fill="none" data-inject-url="https://fixdate.io/modelo-invitacion/125/img/adorno-titulo.svg" className="injectable adorno-titulo">
  <g>
    <path d="M181.146 8.81658C181.216 8.81658 181.286 8.80658 181.346 8.77658C181.636 8.66658 181.776 8.34658 181.676 8.05658C181.666 8.01658 181.506 7.45658 182.076 6.65658C183.436 4.78658 188.796 1.63658 209.356 1.63658H361.336C361.646 1.63658 361.906 1.38658 361.906 1.06658C361.906 0.746582 361.656 0.496582 361.336 0.496582H209.356C189.166 0.496582 182.846 3.52658 181.096 6.07658C180.226 7.33658 180.566 8.33658 180.606 8.44658C180.696 8.67658 180.906 8.80658 181.136 8.80658L181.146 8.81658Z"></path>
    <path d="M181.146 22.5965L186.516 15.2365L181.146 7.32654L175.566 14.9565L181.146 22.5965Z" fill="#D3AF9B"></path>
    <path d="M267.326 1.26654C267.326 1.26654 280.216 1.20654 286.586 7.21654C286.586 7.21654 294.516 1.17654 304.986 1.07654L267.316 1.26654H267.326Z"></path>
    <path d="M181.316 8.80658C181.546 8.80658 181.756 8.67658 181.836 8.44658C181.876 8.33658 182.216 7.34658 181.346 6.07658C179.596 3.53658 173.276 0.496582 153.086 0.496582H1.11626C0.806265 0.496582 0.546265 0.746582 0.546265 1.06658C0.546265 1.38658 0.796265 1.63658 1.11626 1.63658H153.096C173.656 1.63658 179.016 4.78658 180.376 6.65658C180.966 7.45658 180.786 8.02658 180.776 8.05658C180.686 8.34658 180.836 8.66658 181.126 8.77658C181.186 8.79658 181.256 8.80658 181.316 8.80658Z"></path>
    <path d="M95.1363 1.26654C95.1363 1.26654 82.2463 1.20654 75.8763 7.21654C75.8763 7.21654 67.9463 1.17654 57.4763 1.07654L95.1363 1.26654Z"></path>
  </g>
</svg>

            <div className="info-col">

              <div className="info-box">
                <h6>{t("ceremonia.dia")}</h6>
                <p>{t("ceremonia.dia_completo")}</p>
               
              </div>

              <div className="info-box">
                <h6>{t("ceremonia.lugar_t")}</h6>
                <p>{t("ceremonia.lugar")}</p>
                {i18n.language === 'es'? (
                  <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdr1WllR6xPIBS6rpUBT0CHcbzHyToo5HuJiXcCM04buRJd3g/viewform?usp=sf_link" data-evento="Fiesta" className="boton confirmar-asistencia">{t("confirmar")}</a>
              
                ):(
                  <span data-evento="Fiesta" className="boton confirmar-asistencia">{t("confirmar")}</span>              
                )}
              </div>

              <div className="info-box">
                <h6>{t("ceremonia.direccion_t")}</h6>
                <p>{t("ceremonia.direccion")}</p>
                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/RfiJxWhFBJjKbfhh8" data-evento="Ceremonia" className="boton modal-como-llegar">{t("como")}</a>
              </div>

            </div>


          </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-0">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none"  className="injectable adorno-esquina adorno-esquina-1">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none"  className="injectable adorno-esquina adorno-esquina-2">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none"  className="injectable adorno-esquina adorno-esquina-3">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg></div>

      </div>


      <div className=" col-md-6 col-fiesta d-flex justify-content-center text-center">

        <div className="box-bordes-adorno d-flex justify-content-center">

          <div className="box-color-col degradez-opcional-var">


            <div className="anim-fiesta" ref={animationContainerParty}/>

            <h3>
              {t("celebracion.titulo")}</h3>


            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362 23" fill="none" className="injectable adorno-titulo">
  <g>
    <path d="M181.146 8.81658C181.216 8.81658 181.286 8.80658 181.346 8.77658C181.636 8.66658 181.776 8.34658 181.676 8.05658C181.666 8.01658 181.506 7.45658 182.076 6.65658C183.436 4.78658 188.796 1.63658 209.356 1.63658H361.336C361.646 1.63658 361.906 1.38658 361.906 1.06658C361.906 0.746582 361.656 0.496582 361.336 0.496582H209.356C189.166 0.496582 182.846 3.52658 181.096 6.07658C180.226 7.33658 180.566 8.33658 180.606 8.44658C180.696 8.67658 180.906 8.80658 181.136 8.80658L181.146 8.81658Z"></path>
    <path d="M181.146 22.5965L186.516 15.2365L181.146 7.32654L175.566 14.9565L181.146 22.5965Z" fill="#D3AF9B"></path>
    <path d="M267.326 1.26654C267.326 1.26654 280.216 1.20654 286.586 7.21654C286.586 7.21654 294.516 1.17654 304.986 1.07654L267.316 1.26654H267.326Z"></path>
    <path d="M181.316 8.80658C181.546 8.80658 181.756 8.67658 181.836 8.44658C181.876 8.33658 182.216 7.34658 181.346 6.07658C179.596 3.53658 173.276 0.496582 153.086 0.496582H1.11626C0.806265 0.496582 0.546265 0.746582 0.546265 1.06658C0.546265 1.38658 0.796265 1.63658 1.11626 1.63658H153.096C173.656 1.63658 179.016 4.78658 180.376 6.65658C180.966 7.45658 180.786 8.02658 180.776 8.05658C180.686 8.34658 180.836 8.66658 181.126 8.77658C181.186 8.79658 181.256 8.80658 181.316 8.80658Z"></path>
    <path d="M95.1363 1.26654C95.1363 1.26654 82.2463 1.20654 75.8763 7.21654C75.8763 7.21654 67.9463 1.17654 57.4763 1.07654L95.1363 1.26654Z"></path>
  </g>
</svg>

            <div className="info-col">

              <div className="info-box">
                <h6>{t("ceremonia.dia")}</h6>
                <p>{t("celebracion.dia_completo")}</p>               
              </div>

              <div className="info-box">
                <h6>{t("ceremonia.lugar_t")}</h6>
                <p>{t("celebracion.lugar")}</p>
                {i18n.language === 'es'? (
                  <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSdr1WllR6xPIBS6rpUBT0CHcbzHyToo5HuJiXcCM04buRJd3g/viewform?usp=sf_link" data-evento="Fiesta" className="boton confirmar-asistencia">{t("confirmar")}</a>
              
                ):(
                  <span data-evento="Fiesta" className="boton confirmar-asistencia">{t("confirmar")}</span>              
                )}
              </div>

              <div className="info-box">
                <h6>{t("ceremonia.direccion_t")}</h6>
                <p>{t("celebracion.direccion")}</p>
                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/cchCFDryqfHaeHgRA" data-evento="Fiesta" className="boton modal-como-llegar">{t("como")}</a>
              </div>

            </div>

          </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-0">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none"  className="injectable adorno-esquina adorno-esquina-1">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-2">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-3">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg></div>

      </div>


    </div>
  </div>
        </section>
    )
}