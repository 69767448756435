import lottie from "lottie-web";
import tips from './tips.json';
import { useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";

export function Web() {
  let animationContainer = createRef();
  const { t } = useTranslation();
  useEffect(() => {
      const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      animationData: tips
      });
      return () => anim.destroy();
  }, [animationContainer]);

    return (
        <section className="fiesta">

  <div className="sombra-horizontal sombra-top"></div>

  <div className="container">
    <div className="row text-center d-flex justify-content-center align-items-center">   
      <div className="col-md-4 item-fiesta">

        <div className="w-100 box-bordes-adorno content-item-fiesta">

          <div className="box-color-col d-flex flex-column justify-content-between align-items-center degradez-opcional-var">

            <h3>{t("web.titulo")}</h3>

            <div className="d-flex justify-content-center align-items-center content-anim-fiesta">
              <div className="anim-tips" ref={animationContainer}/>
            </div>

            <p>
            {t("web.frase_1")}<br/>{t("web.frase_2")}</p>

            <a href="https://albertoymaria.com/" target="_blank" rel="noopener noreferrer" className="boton  modal-tips">
            https://albertoymaria.com/            </a>
          </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-0">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-1">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none" className="injectable adorno-esquina adorno-esquina-2">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" fill="none"  className="injectable adorno-esquina adorno-esquina-3">

  <g>
    <path className="fondo-adorno" d="M37 0H0V37H37V0Z"></path>

    <path className="punto-adorno" d="M0.75 37V33.79H3.38C20.18 33.79 33.79 20.17 33.79 3.38V0.75H36.99"></path>
    
    <path className="circulo-adorno" d="M5.80002 11.56C8.99223 11.56 11.58 8.97221 11.58 5.78C11.58 2.58779 8.99223 0 5.80002 0C2.60781 0 0.0200195 2.58779 0.0200195 5.78C0.0200195 8.97221 2.60781 11.56 5.80002 11.56Z"></path>
  </g>

</svg></div>

      </div>



    </div>
  </div>

  <div className="sombra-horizontal sombra-bottom"></div>


</section>
    )
}