import ContainerPortada from "./ContainerPortada/ContainerPortada";
import acuarela from '../images/acuarela.jpg';

function Portada() {
  return (
    <section className="portada">
        <div className="d-md-flex">
          <div className="portada-picture">
            <img src={acuarela} alt="Logo" height="100%" width="100%" />
            <div className="sombra-hosrizontal sombra-bottom"></div>
          </div>
          <ContainerPortada />               
        </div>
        <div className="sombra-horizontal sombra-bottom"></div>
      </section>      
  );
}

export default Portada;