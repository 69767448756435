import './Corner.css';

function Corner(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="" x="0px" y="0px" viewBox="0 0 219.1 257.9" xmlSpace="preserve" className={props.class}>
	
	<g>
		<path className="st0" d="M25,25.5C25,25.5,25,25.5,25,25.5C24.9,25.5,24.9,25.5,25,25.5c-0.3,0-4.3,0.5-6.7-2.4c-2.6-3-3-7.4-3-7.4   s4.4,0.4,7.4,3C25.5,21.2,25,25.5,25,25.5z"></path>
	</g>
	<path className="st1" d="M4.2,5.9c0.4,3.1,2.5,15.2,9.8,23.8c7.2,8.4,19.2,8,21.7,7.8"></path>
	<path className="st1" d="M36.1,37.5c0.2-2.5,0.5-14.5-7.8-21.7C19.7,8.4,7.6,6.3,4.5,5.9"></path>
	<g>
		<path className="st0" d="M168.6,10.2C168.6,10.2,168.6,10.2,168.6,10.2C168.6,10.2,168.6,10.2,168.6,10.2c0.1-0.2,1.9-2.4,4.6-2.2   c2.8,0.2,5.2,2.2,5.2,2.2s-2.4,2-5.2,2.2C170.5,12.6,168.6,10.2,168.6,10.2z"></path>
	</g>
	<g>
		<g>
			<path className="st1" d="M86.3,21.2c0.3-0.4,6.7-11,36.9-11H169"></path>
		</g>
	</g>
	<g>
		<path className="st0" d="M9.8,208.6C9.8,208.6,9.8,208.6,9.8,208.6C9.8,208.6,9.7,208.6,9.8,208.6c-0.2,0.1-2.4,1.9-2.2,4.6   c0.2,2.8,2.2,5.2,2.2,5.2s2-2.4,2.2-5.2C12.2,210.5,9.8,208.6,9.8,208.6z"></path>
	</g>
	<g>
		<g>
			<path className="st1" d="M9.8,209.1v-85.8c0-30.2,10.6-36.6,11-36.9"></path>
		</g>
	</g>
	<g>
		<path className="st0" d="M72.1,11.9c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5   C71.4,13.4,72.1,12.7,72.1,11.9z"></path>
	</g>
	<g>
		<path className="st1" d="M73.5,23.5c0.1,0,5.3-4.8,4.3-9.4c-0.6-2.8-3-3.6-3.9-3.8c-1.6-0.4-3.3,0-4,0.8"></path>
	</g>
	<g>
		<path className="st0" d="M9.5,72.6C8.7,72.6,8,71.9,8,71c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C11.1,71.9,10.4,72.6,9.5,72.6z"></path>
	</g>
	<g>
		<path className="st1" d="M23,75.7c-0.2,0.2-4.4,4.9-9.1,4.9c-0.5,0-1-0.1-1.5-0.2c-2.4-0.5-4.2-2.4-4.8-4.9c-0.5-2.1,0.1-4.3,1.3-5.2"></path>
	</g>
	<g>
		<circle className="st0" cx="60.6" cy="41.4" r="2"></circle>
	</g>
	<g>
		<path className="st1" d="M50.9,40c0,0.3,0.4,8.3,7.6,8.3c4.2,0,4.8-2.7,4.9-3.9c0.1-2.1-1.1-4.3-2.5-4.4"></path>
	</g>
	<g>
		<circle className="st0" cx="38.7" cy="62.1" r="2"></circle>
	</g>
	<g>
		<path className="st1" d="M38.6,51.1c0.1,0,9.5,0.5,9.5,8.8c0,4.1-2.7,5.7-5.3,5.9c-0.1,0-0.3,0-0.4,0c-2.3,0-4.8-1.2-5.1-3.3"></path>
	</g>
	<g>
		<circle className="st0" cx="21.1" cy="49.3" r="2"></circle>
	</g>
	<g>
		<path className="st1" d="M21.3,50.8c1,0.1,2.1-0.8,2.8-2.2c1.1-2.1,0.7-4.7-1.1-6.8c-2.3-2.7-6.7-2.4-9.6-0.5c-3.3,2.1-4.2,5.7-2.5,9.5   c3.9,8.3,13.8,5,23.3,1.8c1.4-0.5,2.8-0.9,4.1-1.4c10.8-3.4,17.4,5.1,17.5,5.1"></path>
	</g>
	<g>
		<path className="st0" d="M53.4,53.9c0,0-0.4,17.3,18.4,18.4C71.7,72.3,73.7,56.3,53.4,53.9z"></path>
	</g>
	<g>
		<path className="st0" d="M49.7,45.7c0-2.4-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4C47.7,50.1,49.7,48.1,49.7,45.7z"></path>
	</g>
	<g>
		<circle className="st0" cx="49.1" cy="22.7" r="2"></circle>
	</g>
	<g>
		<path className="st1" d="M50.6,22.6c0.1,1.5-1,3.1-2.8,4c-2.6,1.3-5.6,0.8-8.1-1.3c-3.7-3-3.5-8-1.4-11.2c1.9-3,6.1-5.5,11.7-2.9   c9.3,4.4,5.7,15.3,2.4,24.8c-0.5,1.4-0.9,2.8-1.4,4.1c-3.1,10,4.8,15.9,4.8,16"></path>
	</g>
	<g>
		<path className="st1" d="M21,21.7c0.2,0.2,18.7,15.4,11.5,38.8c-2.8,8.9-7.7,13.5-12.4,18c-7.7,7.3-15.8,14.8-15.8,42v133.1"></path>
	</g>
	<g>
		<path className="st1" d="M215.4,4.6h-96.5c-27.7,0-35.4,8.2-42.9,16.2c-4.5,4.8-8.8,9.4-17.5,12c-18.1,5.6-31.2-4.9-35.7-9.3"></path>
	</g>
</svg>
  );
}

export default Corner;
