import lottie from "lottie-web";
import regalo from './reg.json';
import adorno from './adorno.json';
import { useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";

export function Regalo() {
    const { t } = useTranslation();
    let animationContainerReg = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerReg.current,
        renderer: "svg",
        loop: true,
        animationData: regalo
        });
        return () => anim.destroy();
    }, [animationContainerReg]);

    let animationContainerAdorno = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerAdorno.current,
        renderer: "svg",
        loop: true,
        animationData: adorno
        });
        return () => anim.destroy();
    }, [animationContainerAdorno]);

    return (
        <section className="regalos degradez-opcional-var">
  <div className="adornos-divisor d-flex justify-content-between">
    <svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999           186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg></div>

  <div className="container">
    <div className="row text-center d-flex justify-content-center align-items-center">

      <div className="content-regalos">

        <div className="anim-adorno-titulo anim-adorno-titulo-2" ref={animationContainerAdorno}/>

        <h2 className="title">{t("regalo.titulo")}</h2>
        <p className="subtitle">{t("regalo.frase")}</p>

        <div className="w-100">

          <div className="anim-regalos" ref={animationContainerReg}/>

        </div>

        <br/>

        <div className="boton modal-regalos">ES77 0182 2069 77 0201741396</div>

      </div>

    </div>
  </div>

  <div className="adornos-divisor d-flex justify-content-between"><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg></div>

</section>
    )
}