import React, { useEffect } from 'react'
import useImagePreloader from './hooks/useImagePreloader'
import CuentaAtras from './components/CuentaAtras';
import Portada from './components/Portada'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import acuarela from './images/acuarela.jpg'
import { PreLoader } from './components/PreLoader/PreLoader';
import { Ceremonia } from './components/Ceremonia/Ceremonia';
import { Galeria } from './components/Galeria/Galeria';
import { Web } from './components/Web/Web';
import { Regalo } from './components/Regalos/Regalo';
import "./i18n";
import { useTranslation } from "react-i18next";

const preloadSrcList: string[] = [
  acuarela
]

function App() {
  const { i18n } = useTranslation();
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const lang = params.get('lang');
    if (lang === 'es' || lang === 'us' || lang === 'it') {
      i18n.changeLanguage(lang);
    }
  },[i18n])

  if (!imagesPreloaded) {
    return (<PreLoader/>)
  }

  return (
    <div className='inv-container container'>
      <Portada/>   
      <CuentaAtras/>   
      <Ceremonia/>
      <Galeria/>
      <Web/>
      <Regalo/>
    </div>
  )
}

export default App;
