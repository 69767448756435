import foto1 from './1.jpg'
import foto2 from './2.jpg'
import foto3 from './3.jpg'
import lottie from "lottie-web";
import adorno from './adorno.json';
import camara from './camara.json';
import { useEffect, createRef } from 'react';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const galeria: any[] = [
    foto1,
    foto2,
    foto3
]

export function Galeria() {
  const { t } = useTranslation();
    var settings = {
        lazyLoad: 'ondemand',
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        dots: true,
        centerPadding: '20px',
        slidesToShow: 3,
        prevArrow: false,
        nextArrow: false,
        responsive: [{
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      };


    
    let animationContainerAdorno = createRef();
    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerAdorno.current,
        renderer: "svg",
        loop: true,
        animationData: adorno
        });
        return () => anim.destroy();
    }, [animationContainerAdorno]);
    let animationContainerCamara = createRef();
    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainerCamara.current,
        renderer: "svg",
        loop: true,
        animationData: camara
        });
        return () => anim.destroy();
    }, [animationContainerCamara]);


    return (
        <section className="galeria degradez-opcional-var">

  <div className="adornos-divisor d-flex justify-content-between">
    <svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg></div>

  <div className="container">

    <div className="row text-center d-flex justify-content-center align-items-center">

      <div className="content-galeria">

        <div className="anim-adorno-titulo anim-adorno-titulo-0" ref={animationContainerAdorno}/>
        

        <h2 className="title">{t("galeria.titulo")}</h2>
        <p className="subtitle">{t("galeria.frase")}</p>

        <div className="anim-galeria" ref={animationContainerCamara}/>
      
      </div>

    </div>

  </div>


  <div className="content-fotos">

    <div className="shadow-left"></div>
    <div className="shadow-right"></div>
    <div className="slider-container">      
    <Slider {...settings} className='carrusel'>
    {galeria.map( (imagen, i) => (
             <div className="polaroid" key={`img_galery_${i}`}>

            <a target="_blank" rel="noopener noreferrer" href={imagen} data-fancybox="galeria" tabIndex="-1">
              <img src={imagen} alt=""/>
            </a>

          </div>
        ))}
    </Slider>
    </div>  
  </div>


  <div className="adornos-divisor d-flex justify-content-between">
    <svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill=""  className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg><svg xmlns="http://www.w3.org/2000/svg" width="" height="6" viewBox="0 0 206 6" fill="" className="injectable adorno-borde">

  <g clipPath="url(#clip0_301_899)">
    <path d="M0.09 3.04997C58.75 4.19997 117.68 4.43997 176.34 3.37997C176.89 3.37997 176.89 2.52997 176.34 2.51997C117.68 1.46997 58.75 1.69997 0.09 2.85997C-0.03 2.85997 -0.03 3.04997 0.09 3.04997Z"></path>
    <path d="M198.47 2.96C198.47 1.36 197.11 0 195.51 0C193.91 0 192.55 1.35 192.55 2.96C192.55 4.57 193.91 5.92 195.51 5.92C197.11 5.92 198.47 4.57 198.47 2.96Z"></path>
    <path d="M189.48 2.51999C189.48 2.51999 186.2 0.519992 186.2 2.51999C186.2 5.51999 189.48 5.51999 189.48 2.51999Z"></path>
    <path d="M205.76 2.51999C205.76 2.51999 202.48 0.519992 202.48 2.51999C202.48 5.51999 205.76 5.51999 205.76 2.51999Z"></path>
  </g>

</svg></div>

</section>
    )
}