import lottie from "lottie-web";
import corazon from './corazon.json';
import { useEffect, createRef } from 'react';

export function PreLoader() {

    let animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        animationData: corazon
        });
        return () => anim.destroy();
    }, [animationContainer]);
    
    return (
        <div className="preloader-area">
            <div className="d-flex justify-content-center align-items-center h-100">
                <div className="loader" ref={animationContainer}/>
                </div>
        </div>
    )
}