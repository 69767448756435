import './Ampersand.css';

function Ampersand (props) {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="_x5F_ampersand" x="0px" y="0px" viewBox="0 0 119 115.1" xmlSpace="preserve" className={props.class}>
        <g>
            <g>
                <path className="st0" d="M115.6,112.6c-1.3,0.2-2.7,0.3-4.2,0.4c-1.5,0.1-3,0.1-4.6,0.1c-1.5,0-2.8,0-3.9,0h-1.1c-3.4,0-6.4-0.3-9-0.9    c-2.6-0.6-5.1-1.4-7.4-2.5c-2.3-1.1-4.5-2.5-6.8-4.2c-2.2-1.7-4.6-3.6-7-5.7c-4.9,3.6-10.2,7-15.8,10c-5.6,3-11.6,4.6-17.9,4.6    c-4.6,0-9-0.8-13.4-2.3c-4.4-1.5-8.3-3.7-11.8-6.4c-3.5-2.8-6.3-6.1-8.4-10c-2.2-3.9-3.2-8.3-3.2-13.3c0-3.9,0.6-7.4,1.8-10.5    c1.2-3.2,2.7-6.1,4.6-8.9c1.9-2.8,4-5.3,6.5-7.7c2.5-2.4,5-4.7,7.7-6.9c-2.3-2.3-4.3-5-5.9-8c-1.6-3-2.4-6.3-2.4-9.9    c0-4.2,0.9-8.1,2.8-11.7c1.9-3.6,4.4-6.7,7.5-9.3c3.1-2.6,6.6-4.7,10.5-6.1c3.9-1.5,7.8-2.2,11.8-2.2c3,0,6.1,0.2,9.2,0.7    c3.1,0.5,6.1,1.3,9,2.5c0.4,0,0.5,0.2,0.5,0.5c0.8,3.2,1.2,6.6,1.2,10.4c0,4.7-0.8,9.1-2.3,13.2c-0.2,0.4-0.5,0.5-0.9,0.4    c-0.4-0.1-0.5-0.3-0.5-0.6l0.2-2.8c0.1-2.6-0.4-4.9-1.4-7.1c-1.1-2.2-2.5-4-4.3-5.6c-1.8-1.6-4-2.8-6.4-3.7    c-2.5-0.9-4.9-1.3-7.4-1.3c-4.9,0-9,1.6-12.2,4.7c-3.2,3.2-4.8,7.2-4.8,12.1c0,4.2,1.4,8.8,4.3,13.8c2.9,5,6.4,10,10.7,15    c4.3,5,8.9,9.9,13.8,14.7s9.4,9,13.5,12.7c1.3,1.2,2.5,2.3,3.7,3.3c1.2,1.1,2.2,2,3.2,2.8c1.9-3,3.6-6.4,5.1-10.1    c1.5-3.7,2.3-7.2,2.3-10.6c0-3.5-1.1-6.1-3.3-7.9c-2.2-1.8-5-2.9-8.3-3.3c-0.4-0.1-0.5-0.3-0.5-0.5c0-0.4,0.2-0.6,0.5-0.7    c2.2-0.7,4.7-1.1,7.4-1.1c2.6,0,5.1,0.3,7.6,0.8c2.5,0.5,4.9,1.4,7.3,2.7c0.2,0.2,0.4,0.5,0.4,0.7c-0.6,2.5-1.3,5.2-2.1,8.3    c-0.8,3-1.8,6.1-2.9,9.1c-1.1,3-2.3,6-3.6,8.8c-1.3,2.8-2.7,5.2-4.2,7.2c5.2,5.2,10.8,9.6,17,13.4c6.2,3.8,12.9,6.2,20.2,7.1    l0.4,1.4C117.8,112.2,116.9,112.4,115.6,112.6z M44.2,75.7c-7-7.1-13.6-14.5-19.9-22.2c-3.2,3.4-5.8,7.3-7.9,11.6    c-2.1,4.3-3.2,8.8-3.2,13.5c0,4,0.8,7.7,2.4,11.2c1.6,3.5,3.7,6.6,6.4,9.3c2.7,2.7,5.8,4.8,9.3,6.4c3.5,1.6,7.3,2.4,11.2,2.4    c4.7,0,9.1-1.1,13.3-3.4c4.2-2.3,7.8-5.2,11-8.7C58.7,89.5,51.2,82.8,44.2,75.7z"></path>
            </g>
        </g>
        </svg>
    )
}

export default Ampersand